import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { postPrizeDistribution } from './definePayoutAPI';


export interface PayoutState {
    tournaPayouts: [];
    status: 'idle' | 'loading' | 'failed';
    errorMsg: string | undefined;
}

const initialState: PayoutState = {
    tournaPayouts: [],
    status: 'idle',
    errorMsg: undefined,
};

export const postPrizeDistributionAsync = createAsyncThunk(
    'prizeDistribution/',
    async (prizeDistributionPayload: {tournaID: number, prizeDistribution: any[]}) => {
        const response = await postPrizeDistribution(prizeDistributionPayload);
        return response.data
    }
);

export const payoutSlice = createSlice({
    name: 'payout',
    initialState,
    reducers: {
        setSelectedView: (state, action: PayloadAction<[]>) => {
            state.tournaPayouts = action.payload
        },
        setPayoutsErrorMsg: (state, action: PayloadAction<string| undefined>) => {
            state.errorMsg = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postPrizeDistributionAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postPrizeDistributionAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                console.log("tournaPayouts afterwards rrrrr")
                if (action.payload.code === 'success') {
                    console.log("tournaPayouts afterwards rrrrr byayaya")
                    state.tournaPayouts = action.payload.data
                    console.log(state.tournaPayouts)
                }
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            })
        }
});

export const { setSelectedView, setPayoutsErrorMsg } = payoutSlice.actions;

// export const selectSelectedView = (state: RootState) => state.chat.selectedView;

export default payoutSlice.reducer;
