import axios from "axios";
import config from '../../config.json';

export function fetchMyResolvedConflicts(any: any) {

    return new Promise<any>((resolve) =>
        axios.get(`${config.baseUrl}tourna/mak/${any.seasonID}/get-season-matches/`)
            .then((res) => {
                console.log('fetched matches ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetchTournaParticipations ', e.message);
            })
    )
}

export function fetchNextRoundMatches(nextRoundPayload: {tournaID: string, round: number}) {

    return new Promise<any>((resolve) =>
        axios.post(`${config.baseUrl}tournas/${nextRoundPayload.tournaID}/get-round-pending-matches/${nextRoundPayload.round}/`)
            .then((res) => {
                console.log('fetched matches ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetchTournaParticipations ', e.message);
            })
    )
}
