import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Tourna } from '../tourna/tournaSlice';
import { addTourna } from './addTournaAPI';

export interface AddTournaState {
    newTourna: Tourna
    formValidity: boolean
}

const initialState: AddTournaState = {
    newTourna: {
        code: 'CODE',
        name: 'Match/Tourna name',
        game: '1',
        private: true,
        entryFee: 0,
        // organiser_id: 0,
        numberOfParticipants: 2,
        tournaType: undefined,
        status: 'ongoing',
        halfLength: 10,
        legacyDefending: true,
        console: 'PC',
        description: 'description noma sana',
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
        joinedParticipantsCount: 0,
        registrationDate: undefined,
        startDate: undefined,
        payout: 0,
        round: 0,
        currentLevel: 'some level'
    },
    formValidity: false,
};


export const addTournaAsync = createAsyncThunk(
    'addTourna/',
    async (tourna: Tourna) => {
        const response = await addTourna(tourna);
        console.log('response ', response);
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const addTournaSlice = createSlice({
    name: 'addTourna',
    initialState,
    reducers: {
        setTournaType: (state, action: PayloadAction<'single' | 'double' | 'swiss' | 'groups' | undefined>) => {
            state.newTourna.tournaType = action.payload;
        },
        setTournaPrivacy: (state, action: PayloadAction<boolean>) => {
            state.newTourna.private = action.payload;
        },
        setTournaName: (state, action: PayloadAction<string>) => {
            state.newTourna.name = action.payload;
        },
        setNumberOfParticipants: (state, action: PayloadAction<number>) => {
            state.newTourna.numberOfParticipants = action.payload;
        },
        setTournaGame: (state, action: PayloadAction<string>) => {
            state.newTourna.game = action.payload;
        },
        setAddTournaFormValidity: (state, action: PayloadAction<boolean>) => {
            state.formValidity = action.payload;
        },
        setTournaEntryFee: (state, action: PayloadAction<number>) => {
            state.newTourna.entryFee = action.payload;
        },
        setTournaDescription: (state, action: PayloadAction<string>) => {
            state.newTourna.description = action.payload;
        },
        addToTournaDescription: (state, action: PayloadAction<string>) => {
            state.newTourna.description = state.newTourna.description.concat(',\n'+action.payload);
        },
        setTournaCode: (state, action: PayloadAction<string>) => { 
            state.newTourna.code = action.payload;
        },
    },
});

export const { setTournaCode, setTournaType, setTournaPrivacy, setTournaName, setNumberOfParticipants, 
    setTournaGame, setAddTournaFormValidity, setTournaEntryFee, addToTournaDescription, setTournaDescription,
} = addTournaSlice.actions;

export const selectNewTourna = (state: RootState) => state.addTourna;

export default addTournaSlice.reducer;
