import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { Game } from '../game/gameSlice';
import { fetchGames } from './gamesAPI';



export interface GamesListState {
    games: Game[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState: GamesListState = {
    games: [],
    status: 'idle'
};

export const fetchGamesAsync = createAsyncThunk(
    'games/',
    async () => {
        const response = await fetchGames();
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const gamesListSlice = createSlice({
    name: 'gamesList',
    initialState,
    reducers: {
        setGamesList: (state, action: PayloadAction<Game[]>) => {
            state.games = action.payload
        },
        addGame: (state, action: PayloadAction<Game>) => {
            state.games.push(action.payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGamesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGamesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.games = action.payload
            });
    },
});

export const { setGamesList } = gamesListSlice.actions;

export const selectGamesList = (state: RootState) => state.gamesList.games;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const addGame = (game: Game): AppThunk => (
    dispatch,
    getState
) => {
    const currentList = selectGamesList(getState());
    if (currentList.length === 0) {
        dispatch(addGame(game));
    }
};

export default gamesListSlice.reducer;
