import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Conflict } from '../conflict/conflictSlice';
import { fetchMatchAsync, Match } from '../match/matchSlice';
import { postScores, resolveConflict, fetchConflict, fetchMatchReports } from './viewConflictAPI';

export interface ViewConflictState {
    selectedView: 'schedule' | 'standing' | 'wahenga' | 'chat' | 'posts';
    match: Match | undefined
    confirmedJoin: boolean
    status: 'idle' | 'loading' | 'failed';
    matchReports: MatchReport []
    conflict: Conflict | undefined
    viewMatchInputs: ViewMatchInputs | undefined,
    errorMsg: string | undefined
    showChatInput: boolean
}

const initialState: ViewConflictState = {
    selectedView: 'standing',
    confirmedJoin: false,
    match: undefined,
    status: 'idle',
    matchReports: [],
    conflict: undefined,
    viewMatchInputs: undefined,
    errorMsg: undefined,
    showChatInput: false
};

export interface ViewMatchInputs {
    playerOneScore: string | undefined;
    playerTwoScore: string | undefined;
    conflictEvidence: []
}

export interface MatchReport{
    id?: string
    reporter_id: number,
    match_id: number,
    player_one_score: string,
    player_two_score: string,
    deleted_at: string | undefined
}

export const postScoresAsync = createAsyncThunk(
    'postScores/',
    async (matchReport: FormData) => {
        const response = await postScores(matchReport);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya postScores', response.data);
        return response.data;
    }
);

export const resolveConflictAsync = createAsyncThunk(
    'resolveConflict/',
    async (matchID: number) => {
        const response = await resolveConflict(matchID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya resolveConflict', response.data);
        return response.data;
    }
);

export const fetchMatchConflictAsync = createAsyncThunk(
    'fetchMatchConflict/',
    async (match_id: number) => {
        const response = await fetchConflict(match_id);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya fetchMatchConflict', response.data);
        return response.data;
    }
);

export const fetchMatchReportsAsync = createAsyncThunk(
    'fetchMatchReports/',
    async (match_id: number) => {
        const response = await fetchMatchReports(match_id);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya fetchMatchReports', response.data);
        return response.data;
    }
);

export const viewMatchSlice = createSlice({
    name: 'viewMatch',
    initialState,
    reducers: {
        setViewMatchInputs: (state, action: PayloadAction<ViewMatchInputs>) => {
            state.viewMatchInputs = action.payload;
        },
        setConflictWinner: (state, action: PayloadAction<number | undefined>) => {
            console.log('setConflictWinner action.payload')
            console.log(action.payload)
            if (state.match)
                state.match.victor = action.payload;
        },
        setConflict: (state, action: PayloadAction<Conflict | undefined>) => {
            state.conflict = action.payload;
        },
        clearConflictDetails: (state, action: PayloadAction) => {
            console.log("attempting to clear conflict details")
            state.matchReports = [];
            state.conflict = undefined;
            state.viewMatchInputs = undefined;
        },
        setConflictPlayerOneScore: (state, action: PayloadAction<string>) => {
            if(state.match)
                state.match.player_one_score = action.payload;
        },
        setConflictPlayerTwoScore: (state, action: PayloadAction<string>) => {
            if(state.match)
                state.match.player_two_score = action.payload;
        },
        setShowChatInput: (state, action: PayloadAction<boolean>) => {
            state.showChatInput = action.payload;
        },
        setMatchReports: (state, action: PayloadAction<any>) => {
            state.matchReports = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resolveConflictAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(resolveConflictAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                // state.tournas = action.payload
                console.log("do something with startMatchAsync res");
            })
            .addCase(fetchMatchConflictAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMatchConflictAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.conflict = action.payload
            })
            .addCase(fetchMatchReportsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMatchReportsAsync.fulfilled, (state, action) => {
                console.log("fetchMatchReportsAsync fetchMatchReportsAsync.fulfilled ", fetchMatchReportsAsync.fulfilled)
                // state.status = 'idle';
                // state.matchReports = action.payload.data
                return {
                    ...state,
                    status: 'idle',
                    matchReports: action.payload.data
                }
            })
            .addCase(fetchMatchAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMatchAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.match = action.payload
                console.log("state.match ", state.match)
            });
            
    },
});

export const { setViewMatchInputs, setConflictWinner, setConflict, setConflictPlayerOneScore, setConflictPlayerTwoScore,
    setShowChatInput, clearConflictDetails, setMatchReports } = viewMatchSlice.actions;

export const selectViewConflictDetails = (state: RootState) => state.viewConflict;

export default viewMatchSlice.reducer;
