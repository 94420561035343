import React, { useState } from 'react';
import './StandingsModeSelection.css';

const StandingsModeSelection: React.FC = () => {
    const [selectedMode, setSelectedMode] = useState<string>('bundesliga');

    const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedMode(event.target.value);
    };

    return (
        <div className="standings-mode-selection">
            <div className="radio-container">
                <label>
                    <input
                        type="radio"
                        name="prize-mode"
                        value="bundesliga"
                        checked={selectedMode === 'bundesliga'}
                        onChange={handleModeChange}
                    />
                    bayern mode
                </label>
                <label>
                    <input
                        type="radio"
                        name="prize-mode"
                        value="leicester"
                        checked={selectedMode === 'leicester'}
                        onChange={handleModeChange}
                    />
                    leicester mode
                </label>
            </div>
            <div className="info-icon-container">
                <span className="info-icon" data-tooltip="bayern mode uses weighting first to break ties (favours late round performances), while leicester mode uses them as a last resort after opp points and GD (favours weaker participants).">
                    ℹ️
                </span>
            </div>
        </div>
    );
};

export default StandingsModeSelection;
