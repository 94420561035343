import React, { useState } from 'react';
import './Slider.css';

interface SliderProps {
    min: number;
    max: number;
    value: number;
    onChange: (value: number) => void;
}

const Slider: React.FC<SliderProps> = ({ min, max, value, onChange }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipValue, setTooltipValue] = useState(value);

    const handleMouseDown = () => setShowTooltip(true);
    const handleMouseUp = () => setShowTooltip(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(e.target.value);
        setTooltipValue(newValue);
        onChange(newValue);
    };

    return (
        <div className="slider-container">
            <input
                type="range"
                min={min}
                max={max}
                value={value}
                onChange={handleChange}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                className="slider"
            />
            {showTooltip && (
                <div className="slider-tooltip" style={{ left: `${(tooltipValue - min) / (max - min) * 100}%` }}>
                    {tooltipValue}%
                </div>
            )}
        </div>
    );
};

export default Slider;
