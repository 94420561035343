import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    addTournaAsync,
    //  setTournaType, setTournaPrivacy, setTournaEntryFee,
    // setTournaName, setTournaRegistrationDate, setNumberOfParticipants, setTournaDate,
    // , setTournaGame, addToTournaDescription, setTournaDescription, setOpenTournament
} from "./addTournaModalSlice";
// import { useHistory } from "react-router-dom";
import styles from "./AddTournaModal.module.css";
import { Tourna } from "../tourna/tournaSlice";
import { fetchGamesAsync } from "../games/gamesSlice";
import { Game } from "../game/gameSlice";
import { ErrorInterface, randomString } from "../../utils";
import { setErrorMsg } from "../../appSlice";
import { fetchTournasAsync } from "../tournas/tournasSlice";

interface AddTournaModalProps {
    open?: boolean;
    closeModal: () => void;
}

const AddTournaModal: React.FC<AddTournaModalProps> = (props) => {
    const { closeModal } = props;
    const dispatch = useAppDispatch();
    const [modalOpen] = useState(true);
    const modalRef = useRef(null);
    // const newTourna = useAppSelector(state => state.addTourna.newTourna);
    const gamesList = useAppSelector<Game[] | any>(state => state.gamesList);
    const [newTournaNameError, setNewTournaNameError] = useState<ErrorInterface | null>(null);
    const [newTournaDateError, setNewTournaDateError] = useState<ErrorInterface | null>(null);
    const [newTournaRegDateError, setNewTournaRegDateError] = useState<ErrorInterface | null>(null);
    const [newTournaNumberOfParticipantsError, setNewTournaNumberOfParticipantsError] = useState<ErrorInterface | null>(null);
    const [frequentRules, setFrequentRules] = useState<string[]>(['10 mins', 'tactical defending']);
    const userID = useAppSelector(state => state.app.userID);

    const [code,] = useState(randomString(4).toUpperCase());
    const [selectedConsole,] = useState("console");
    // const [currentLevel, setCurrentLevel] = useState("");
    const [description,] = useState("enter text here");
    const [entryFee, setEntryFee] = useState<number>(0);
    const [game, setGame] = useState("1");
    const [halfLength, setHalfLength] = useState<number>(10);
    const [legacyDefending,] = useState<boolean>(true);
    const [numberOfParticipants, setNumberOfParticipants] = useState<number | null>(0);
    const [openTournament, setOpenTournament] = useState<boolean>(true);
    const [isPrivate, setIsPrivate] = useState<boolean>(false);
    const [tournaType, setTournaType] = useState<"single" | "double" | "swiss" | "groups" | undefined>("swiss");
    const [tournaName, setTournaName] = useState("tourna_" + code);
    const [tournaDescription, setTournaDescription] = useState("description noma sana")
    const [startDate, setTournaDate] = useState<string | undefined>(undefined)
    const [registrationDate, setTournaRegistrationDate] = useState<string | undefined>(undefined);

    // const addToTournaDescription = (description: string) => {

    // }
    const [, setIsFormValid] = useState<boolean>(false);



    useEffect(() => {
        dispatch(fetchGamesAsync());
        // console.log('newTourna ', newTourna);
    }, [dispatch]);

    useEffect(() => {
        if (newTournaNameError || newTournaDateError || newTournaRegDateError) {
            setIsFormValid(true);
        }
        else setIsFormValid(false);
    }, [newTournaNameError, newTournaDateError, newTournaRegDateError]);


    const clickListener = useCallback((e: MouseEvent) => {
        // if (!(modalRef.current! as any).contains(e.target)) setModalOpen(false);
        if (!(modalRef.current! as any)?.contains(e.target)) closeModal();
    }, [closeModal, modalRef]);

    useEffect(() => {
        document.addEventListener("click", clickListener);

        return () => {
            document.removeEventListener("click", clickListener);
        };

    }, [modalOpen, clickListener]);

    const saveDetails = () => {
        if (!userID) {
            console.log('alaaa');
            dispatch(setErrorMsg("login to do that. (extreme right button)"));
            return;
        }

        if (!startDate) {
            dispatch(setErrorMsg("need to set a tourna date."));
            return;
        }

        let tourna: Tourna = {
            name: tournaName,
            code: code,
            description: tournaDescription,
            entryFee: entryFee,
            game: game,
            private: isPrivate,
            organiser_id: userID,
            numberOfParticipants: numberOfParticipants,
            tournaType: tournaType,
            joinedParticipantsCount: 0,
            registrationDate: registrationDate,
            startDate: startDate,
            status: "pending",
            payout: 0,
            round: 0,
            currentLevel: "some level",
            halfLength: halfLength,
            legacyDefending: legacyDefending,
            console: selectedConsole,
            createdAt: Date.now().toString(),
            updatedAt: Date.now().toString(),
        };
        tourna.organiser_id = userID;
        tourna.createdAt = Date.now().toString();
        tourna.updatedAt = Date.now().toString();
        console.log('tourna kichwa ', tourna);
        dispatch(addTournaAsync(tourna));
        document.removeEventListener("click", clickListener);
        closeModal();
        setTimeout(() => dispatch(fetchTournasAsync()), 200)
    }

    const onChangeGame = (e: any) => {
        setGame(e.target.value)
        let selectedGame = gamesList.games.filter((game: Game) => {
            return game.id === e.target.value;
        })[0];
        // console.log('selectedGame ', selectedGame);
        setFrequentRules(selectedGame.frequentRules)
    }

    return (
        <div className={styles.AddTournaModal} ref={modalRef}>
            <div className={styles.AddTournaModal_actions_div}>
                <h6>create tourna</h6>
                <div className={styles.AddTournaModal_actions_div_btns}>
                    <button className={styles.AddTournaModal_cancel_btn} onClick={() => closeModal()}>cancel</button>
                    <button className={styles.AddTournaModal_save_btn} onClick={() => saveDetails()}>save details</button>
                </div>
            </div>
            <form className={styles.AddTournaModal_form}>

                <div className={styles.AddTournaModal_form_game_div}>
                    <div className="dropdown">
                        {gamesList.status !== "idle" ? "Fetching games.." :
                            <div>
                                <div>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        {'select game'}
                                    </label>
                                </div>
                                <select name="tourna games" id="tourna-game" onChange={onChangeGame} className="dropdown-toggle" aria-labelledby="dropdownMenuButton">
                                    {gamesList ? gamesList?.games.map((game: Game) => (
                                        <option key={game.code} value={game.id}>{game.name}</option>
                                    )) : <option key={"no games"} value={"no games"}>no games</option>}
                                </select>
                            </div>
                        }
                    </div>
                </div>

                <hr />
                <div className={styles.AddTournaModal_form_match_type}>
                    <div className="form-check">
                        <input className="form-check-input" onChange={() => {}}  onClick={() => { setTournaType('single'); }} checked={tournaType === "single"} type="radio" name="cardinality" id="cardinality1" />
                        <label className="form-check-label" htmlFor="cardinality1">
                            single match
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={() => {}} onClick={() => { setTournaType('swiss') }} checked={tournaType === "swiss"} type="radio" name="cardinality" id="cardinality2" />
                        <label className="form-check-label" htmlFor="cardinality2">
                            tourna
                        </label>
                    </div>
                </div>
                <hr />
                {tournaType === "swiss" && <div>
                    {/* <div className={styles.AddTournaModal_form_type_field_div}>
                        <div className="dropdown">
                            <select name="tourna types" id="tourna-type" className="dropdown-toggle" aria-labelledby="dropdownMenuButton">
                                <option value="">--Tourna type--</option>
                                <option value="single" >Single Elimination</option>
                                <option value="double">Double Elimination</option>
                                <option value="cat">Round Robbin</option>
                                <option value="groups">Groups</option>
                                <option value="swiss">Swiss</option>
                            </select>
                        </div>
                    </div> */}

                    <div className="form-check">
                        <input className="form-check-input" onChange={() => setOpenTournament(true)} checked={openTournament} type="radio" name="participant-count" id="participant-count1" />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            unlimited number of participants
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={() => setOpenTournament(false)} checked={!openTournament} type="radio" name="participant-count" id="participant-count2" />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            fixed number of participants
                        </label>
                    </div>
                    <div className={styles.AddTournaModal_form_participant_count_field_div}>
                        {newTournaNumberOfParticipantsError !== null && <div>
                            <label className={styles.error} htmlFor="participantCount">
                                {newTournaNumberOfParticipantsError.detail}
                            </label>
                        </div>}
                        {(!openTournament) && <input onInput={(e) => {
                            const element = e.currentTarget as HTMLInputElement;
                            let numericValue = Number.parseInt(element.value);
                            if (numericValue > 0 && Number.isInteger(numericValue)) {
                                setNumberOfParticipants(Number.parseInt(element.value))
                                setNewTournaNumberOfParticipantsError(null)
                            }
                            else setNewTournaNumberOfParticipantsError({ code: 'error', detail: 'Invalid number of participants' })
                        }} type="number" placeholder="number of participants" name="participantCount" />}
                    </div>
                    <hr />
                </div>}
                <div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={(e) => {
                            if (isPrivate)
                                setIsPrivate(false)
                            else setIsPrivate(true)
                        }} checked={isPrivate} type="checkbox" name="privacy" id="privacy1" />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            private
                        </label>
                    </div>
                </div>
                <hr />
                <div className={styles.AddTournaModal_form_name_field_div}>
                    {newTournaNameError !== null && <div>
                        <label className={styles.error} htmlFor="flexRadioDefault1">
                            {newTournaNameError.detail}
                        </label>
                    </div>}
                    <div>
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            {tournaType === "single" ? "match name " : "tourna name"} (can be blank)
                        </label>
                    </div>
                    <input onInput={(e) => {
                        const element = e.currentTarget as HTMLInputElement;
                        // if(/^[a-z0-9]+$/i.test(element.value))
                        if (element.value.length < 30) {
                            setTournaName(element.value)
                            setNewTournaNameError(null)
                        }
                        else setNewTournaNameError({ code: 'error', detail: 'Tourna name too long' })
                    }
                    } type="text" placeholder={tournaType === "single" ? "match name " : "tourna name"} name="tourna_name" />
                </div>
                <hr />
                <div className={styles.NewTournaModal_form_reg_date_field_div}>
                    <div className="form-check">
                        <input className="form-check-input" onChange={(e) => {
                            setTournaRegistrationDate(undefined);
                        }
                        } checked={registrationDate === undefined} type="radio" name="deadline" id="deadline1" />
                        <label className="form-check-label" htmlFor="cardinality1">
                            Open registration (no deadline)
                        </label>

                    </div>
                    <div className="form-check">
                        <input className="form-check-input" onChange={() => setTournaRegistrationDate(new Date(new Date().getDate() + 1).toISOString())} checked={registrationDate !== undefined} type="radio" name="deadline" id="deadline2" />
                        <label className="form-check-label" htmlFor="cardinality2">
                            Set registration deadline
                        </label>
                        {newTournaRegDateError !== null && <div>
                            <label className={styles.error} htmlFor="flexRadioDefault1">
                                {newTournaRegDateError.detail}
                            </label>
                        </div>}
                    </div>
                    {registrationDate !== undefined && <input onInput={(e) => {
                        const element = e.currentTarget as HTMLInputElement;
                        console.log('date se;ected', element.value)
                        // if(/^[a-z0-9]+$/i.test(element.value))
                        let dateToday = new Date().setHours(0, 0, 0, 0);
                        if (Date.parse(element.value) < dateToday) {
                            setNewTournaRegDateError({ code: 'error', detail: 'invalid registration deadline date' });
                            return;
                        }
                        setTournaRegistrationDate(element.value);
                        if (Date.parse(element.value) > Date.parse(startDate === undefined ? '2021-10-10' : startDate)) {
                            setNewTournaDateError({ code: 'error', detail: 'tourna date before registration date' });
                        }
                        else {
                            setTournaRegistrationDate(element.value)
                            setNewTournaRegDateError(null);
                            setNewTournaDateError(null);
                        }
                    }} type="datetime-local" min={Date.now()} value={registrationDate} placeholder={"registration deadline"} name="registration-date" />}
                </div>
                <hr />
                <div>
                    <div>
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Set tourna date
                        </label>
                    </div>
                    <input onInput={(e) => {
                        // <input onInput={(e) => {
                        const element = e.currentTarget as HTMLInputElement;
                        // if(/^[a-z0-9]+$/i.test(element.value))
                        setTournaDate(element.value);
                        let dateToday = new Date().setHours(0, 0, 0, 0);
                        if (Date.parse(element.value) < dateToday) {
                            setNewTournaDateError({ code: 'error', detail: 'tourna date in the past' });
                            return;
                        }
                        if (registrationDate === null) {
                            setTournaDate(element.value);
                            setNewTournaDateError(null);
                            setNewTournaRegDateError(null);
                            return;
                        }
                        if (Date.parse(element.value) < Date.parse(registrationDate === undefined ? '2021-10-10' : registrationDate)) {
                            setNewTournaDateError({ code: 'error', detail: 'tourna date before registration date' });
                        }
                        else {
                            setTournaDate(element.value)
                            setNewTournaDateError(null);
                            setNewTournaRegDateError(null);
                        }
                    }} type="datetime-local" min={Date.now()} value={startDate ? startDate : new Date().getDate() + 5} placeholder={tournaType === "single" ? "match date " : "tourna date"} name="tourna-date" />
                </div>
                <hr />

                <div className={styles.AddTournaModal_form_entry_fee_field_div}>
                    <div>
                        <label className="form-check-label" htmlFor="cardinality2">
                            Entry fee
                        </label>
                    </div>
                    <input onChange={(e) => {
                        const element = e.currentTarget as HTMLInputElement;
                        // if(/^[a-z0-9]+$/i.test(element.value))
                        setEntryFee(Number.parseInt(element.value));
                    }} type="number" value={entryFee} placeholder="entry fee" name="fee" />
                </div>
                <hr />

                <div className={styles.AddTournaModal_form_match_length_field_div}>
                    <div>
                        <label className="form-check-label" htmlFor="halfLength">
                            Match length
                        </label>
                    </div>
                    <input onChange={(e) => {
                        const element = e.currentTarget as HTMLInputElement;
                        // if(/^[a-z0-9]+$/i.test(element.value))
                        setHalfLength(Number.parseInt(element.value));
                    }} type="number" value={halfLength} placeholder="match legth" name="half-length" />
                </div>
                <hr />

                <div className={styles.AddTournaModal_form_description_field_div}>
                    {frequentRules ? frequentRules.map((rule: string, key) => (
                        <div key={key}>
                            <input onChange={(e) => {
                                if (e.target.checked === true)
                                    tournaDescription.concat(',\n' + e.target.value);
                            }} type="checkbox" id={key.toString()} name={"rule" + key} value={rule} placeholder={tournaDescription} />
                            <label htmlFor="vehicle1"> {rule}</label><br />
                        </div>
                    )) : ''}
                    <textarea onChange={(e) => {
                        const element = e.currentTarget as HTMLTextAreaElement;
                        // if(/^[a-z0-9]+$/i.test(element.value))
                        setTournaDescription(element.value);
                    }} style={{ marginTop: '1vh' }} value={description} rows={4} placeholder="other info" name="description" />
                </div>
                <div style={{
                    display: 'flex',

                }}>
                    <hr style={{ marginTop: '1.65vh', width: '50vw' }} />
                    <span>end</span>
                    <hr style={{ marginTop: '1.65vh', width: '50vw' }} />
                </div>
            </form>

        </div>
    );
};

export default AddTournaModal;
