import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { Tourna } from '../tourna/tournaSlice';
import { fetchTournas, searchTournas } from './tournasAPI';

export interface TournaListState {
    tournas: Tourna[];
    status: 'idle' | 'loading' | 'failed';
    errorMsg: string | undefined
    isSearching: boolean
}

const initialState: TournaListState = {
    tournas: [],
    status: 'idle',
    errorMsg: undefined,
    isSearching: false,
};

export const fetchTournasAsync = createAsyncThunk(
    'tournas/',
    async () => {
        const response = await fetchTournas();
        // The value we return becomes the `fulfilled` action payload
        console.log('fetchTournasAsync response ', response);
        return response.data;
    }
);

export const searchTournasAsync = createAsyncThunk(
    'searchTournas/',
    async (searchString: string) => {
        const response = await searchTournas(searchString);
        // The value we return becomes the `fulfilled` action payload
        console.log('searchTournasAsync response ', response);
        return response.data;
    }
);

export const tournasListSlice = createSlice({
    name: 'tournasList',
    initialState,
    reducers: {
        setTournasList: (state, action: PayloadAction<Tourna[]>) => {
            state.tournas = action.payload
        },
        addNewTourna: (state, action: PayloadAction<Tourna>) => {
            state.tournas.push(action.payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTournasAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTournasAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tournas = action.payload
            })
            .addCase(searchTournasAsync.pending, (state) => {
                state.status = 'loading';
                state.isSearching = true;
            })
            .addCase(searchTournasAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tournas = action.payload;
                state.isSearching = false;
            });

    },
});

export const { setTournasList, addNewTourna, } = tournasListSlice.actions;

export const selectTournasList = (state: RootState) => state.tournasList.tournas;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const addTourna = (tourna: Tourna): AppThunk => (
    dispatch,
    getState
) => {
    const currentList = selectTournasList(getState());
    if (currentList.length === 0) {
        dispatch(addTourna(tourna));
    }
};

export default tournasListSlice.reducer;
