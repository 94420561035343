import React from 'react';
import { Route , withRouter} from 'react-router-dom';
import './ErrorBoundary.css'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log('componentDidCatch imefiriwa');
        this.setState({
            error: error,
            errorInfo: errorInfo,
            hasError: true
        })
    }

    RedirectButton = () => (
        <Route render={({ history}) => (
          <button
            type='button'
            onClick={() => { history.push('/') }}
          >
            Return to lounges.
          </button>
        )} />
      )

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div className="overlay">
                <div className="overlay-text">
                    <h1>Something went wrong.</h1>
                    <hr></hr>
                    
                </div>
                {/* <Login></Login> */}
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;