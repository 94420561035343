/* eslint-disable */
import React, { useState, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector, } from "../../app/hooks";
import {
    MatchReport, postScoresAsync, setConflictWinner, ViewMatchInputs,
    setConflictPlayerOneScore, setConflictPlayerTwoScore, selectViewConflictDetails, setConflict, clearConflictDetails, fetchMatchReportsAsync, setMatchReports
} from "../view-conflict/viewConflictSlice";
// import { useHistory } from "react-router-dom";
import styles from "./ViewConflictPage.module.css";
import CloseIcon from '../../assets/icons/close_icon.png';
import { selectTourna } from "../tourna/tournaSlice";
import { useNavigate } from "react-router-dom";
import { isTokenExpired, setErrorMsg } from "../../appSlice";
import AppBar from "../app-bar/AppBar";
import Chat from "../chat/Chat";
import MatchReportSummary from "../match-report-summary/MatchReportSummary";
import { fetchConflictsAsync } from "../conflicts/conflictsSlice";
import ViewConflict from "../view-conflict/ViewConflict";
import { useSelector } from "react-redux";


interface ViewConflictProps {
    open?: boolean;
    closeModal: () => void;
}

const ViewConflictPage = () => {
    const dispatch = useAppDispatch();
    const [showedReportName, setShowedReportName] = useState<'playerOneReport' | 'playerTwoReport' | 'adminReport' | ''>('');
    const conflictDetails = useAppSelector(selectViewConflictDetails)
    // const matchReports = useSelector(selectViewConflictDetails, (prev, next) => prev.matchReports !== next.matchReports).matchReports
    const matchReports = useSelector(selectViewConflictDetails).matchReports;
    const [adminUsername, setAdminUsername] = useState<string>('admin')

    const userID = useAppSelector(state => state.app.userID);
    const navigate = useNavigate();

    const app = useAppSelector((state) => state.app);


    const close = () => {
        dispatch(clearConflictDetails());
        navigate(-1)
    }

    const refreshReports = () => {
        dispatch(setMatchReports(null))
        if (conflictDetails.conflict)
            dispatch(fetchMatchReportsAsync(conflictDetails.conflict.match));
        else dispatch(setErrorMsg("no conflict selected"))
    }

    return (
        <div>
            <AppBar />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6 style={{ marginTop: '0vh' }}>viewing match: {conflictDetails?.match?.code}</h6>
                <div style={{ marginTop: '-2vh' }}>
                    <img src={CloseIcon} alt="menu" width="50" height="40" onClick={close} />
                </div>

            </div>
            <div className={styles.ViewConflictPage_nav_bar}>
                {<div className={styles.ViewConflictPage_nav_bar}>
                    <button onClick={() => { setShowedReportName('adminReport') }}>
                        {adminUsername}'s report
                    </button>
                    <button onClick={() => setShowedReportName("playerOneReport")}>
                        player one's report
                    </button>
                    <button onClick={() => setShowedReportName("playerTwoReport")}>
                        player two's report
                    </button>
                </div>}

                <button onClick={() => refreshReports()}>
                    refresh all reports
                </button>
            </div>
            {"matchReports.length" + matchReports?.length}
            {matchReports && matchReports?.length > 0 && <ViewConflict
                closeModal={() => { }} />}
        </div>
    );
};

export default ViewConflictPage;
