// A mock function to mimic making an async request for data
export function fetchMessage() {
    return new Promise<{ code: string, data: any }>((resolve) =>
      setTimeout(() => resolve({ code: "success", data: [
          {
            id: "823473",
            name: "Juja Base",
            code: "JB-221",
            createdAt: "23-09-21",
          },
          {
            id: "823483",
            name: "Roysa Base",
            code: "RB-221",
            createdAt: "23-09-21",
          },
          {
            id: "823484",
            name: "Uta Base",
            code: "UB-221",
            createdAt: "23-09-21",
          }
      ] }), 1100)
    );
  }
  