import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, } from '../../app/store';
import { createTribunal, fetchTribunalByTournaID, fetchTribunalMembers, getTribunalsByCreator, removeTribunalMember } from './tribunalAPI';
import { User } from '../user/userSlice';
import { addTribunalMembershipAsync, removeTribunalMembershipAsync } from '../tribunal-membership-modal/tribunalMembershipSlice';

export interface Tribunal {
    id?: number;
    is_active: boolean
    creator?: string
    creator_id: number
    name: string
}

export interface TribunalMembership {
    id?: number;
    user: string;
    tribunal_id: number;
    user_id: number;
}

export interface TribunalState {
    tribunal: Tribunal | undefined;
    tribunalMembers: TribunalMembership[];
    myTribunals: Tribunal[],
    status: 'idle' | 'loading' | 'failed';
    errorMsg: string | undefined
    successMsg: string | undefined
}

const initialState: TribunalState = {
    tribunal: undefined,
    tribunalMembers: [],
    myTribunals: [],
    status: 'idle',
    errorMsg: undefined,
    successMsg: undefined,
};

export const createTribunalAsync = createAsyncThunk(
    'createTribunal/',
    async (tribunalObj: { creator_id: number, name: string }) => {
        const response = await createTribunal(tribunalObj);
        // The value we return becomes the `fulfilled` action payload
        console.log('create tribunal ', response);
        return response.data;
    }
);

export const fetchTribunalByTournaIDAsync = createAsyncThunk(
    'fetchTribunalByTournaID/',
    async (user_id: number) => {
        const response = await fetchTribunalByTournaID(user_id);
        // The value we return becomes the `fulfilled` action payload
        console.log('fetch tribunal by tourna id ', response);
        return response.data;
    }
);

export const fetchTribunalMembersAsync = createAsyncThunk(
    'fetchTribunalMembers/',
    async (user_id: number) => {
        const response = await fetchTribunalMembers(user_id);
        // The value we return becomes the `fulfilled` action payload
        console.log('fetch tribunal members by user id ', response);
        return response.data;
    }
);

export const removeTribunalMemberAsync = createAsyncThunk(
    'updateProfile/',
    async (removeParams: { tribunal_id: number, phone_no: string }) => {
        const response = await removeTribunalMember(removeParams.tribunal_id, removeParams.phone_no);
        // The value we return becomes the `fulfilled` action payload
        console.log('remove tribunal member ', response);
        return response.data;
    }
);

export const getTribunalsByCreatorAsync = createAsyncThunk(
    'getTribunalsByCreator/',
    async (userID: number) => {
        const response = await getTribunalsByCreator(userID);
        // The value we return becomes the `fulfilled` action payload
        console.log('fetch tribunals by creator ', response);
        return response.data;
    }
);

export const tribunalSlice = createSlice({
    name: 'tribunal',
    initialState,
    reducers: {
        setTribunal: (state, action: PayloadAction<Tribunal | undefined>) => {
            state.tribunal = action.payload
        },
        setTribunalError: (state, action: PayloadAction<string | undefined>) => {
            state.errorMsg = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTribunalMembersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTribunalMembersAsync.fulfilled, (state, action: any) => {
                console.log('res ya tribunal members ', action.payload)
                state.status = 'idle';
                // console.log("new season afterwards rrrrr")
                if (action.payload.code === "success") {
                    state.successMsg = "successfully fetched tribunal members.";
                    state.tribunalMembers = action.payload.data
                } else
                    state.errorMsg = action.payload.detail;
            })
            .addCase(fetchTribunalMembersAsync.rejected, (state, action) => {
                state.status = 'idle';
                console.log("rejected, (NEW!) check it out ....")
            })
            .addCase(createTribunalAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createTribunalAsync.fulfilled, (state, action: any) => {
                console.log('res ya create tribunal ', action.payload)
                state.status = 'idle';
                // console.log("new season afterwards rrrrr")
                if (action.payload.id) {
                    state.successMsg = "successfully created tribunal.";
                    state.tribunal = action.payload.data
                } else
                    state.errorMsg = action.payload.detail;
            })
            .addCase(createTribunalAsync.rejected, (state, action) => {
                state.status = 'idle';
                console.log("rejected, (NEW!) check it out ....")
            })
            .addCase(getTribunalsByCreatorAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTribunalsByCreatorAsync.fulfilled, (state, action: any) => {
                console.log('res ya fetch my tribunal ', action.payload)
                state.status = 'idle';
                // console.log("new season afterwards rrrrr")
                if (action.payload.code === "success") {
                    state.successMsg = "successfully fetched my tribunals ";
                    state.myTribunals = action.payload.data
                } else
                    state.errorMsg = action.payload.detail;
            })
            .addCase(getTribunalsByCreatorAsync.rejected, (state, action) => {
                state.status = 'idle';
                console.log("rejected, (NEW!) check it out ....")
            })
            .addCase(addTribunalMembershipAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addTribunalMembershipAsync.fulfilled, (state, action: any) => {
                console.log('res ya add tribunal member ', action.payload)
                state.status = 'idle';
                // console.log("new season afterwards rrrrr")
                if (action.payload.code === "success") {
                    state.successMsg = "successfully added tribunal member.";
                    state.tribunalMembers = action.payload.data
                } else
                    state.errorMsg = action.payload.detail;
            })
            .addCase(addTribunalMembershipAsync.rejected, (state, action) => {
                state.status = 'idle';
                console.log("rejected, (NEW!) check it out ....")
            })
            .addCase(removeTribunalMembershipAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(removeTribunalMembershipAsync.fulfilled, (state, action: any) => {
                console.log('res ya remove tribunal member ', action.payload)
                state.status = 'idle';
                // console.log("new season afterwards rrrrr")
                if (action.payload.code === "success") {
                    state.successMsg = "successfully removed tribunal member.";
                    state.tribunalMembers = action.payload.data
                } else
                    state.errorMsg = action.payload.detail;
            })
            .addCase(removeTribunalMembershipAsync.rejected, (state, action) => {
                state.status = 'idle';
                console.log("rejected, (NEW!) check it out ....")
            });
    }
});

export const { setTribunal, setTribunalError } = tribunalSlice.actions;

export const selectProfile = (state: RootState) => state.tribunalState

export default tribunalSlice.reducer;
