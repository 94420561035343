import axios from "axios";
import config from '../../config.json';

export function addTribunalMember(tribunal_id: number, phone_no: string) {
    return new Promise<any>((resolve) =>
        // axios.get(`${config.baseUrl}accounts/${user_id}/update-account/`,)
        axios.post(`${config.baseUrl}tribunals/${tribunal_id}/add-member/`,  { "phone_no": phone_no })
            .then((res) => {
                console.log('added tribunal member ', res);
                resolve(res);
            })
    )
}

export function fetchTribunalByTournaID(user_id: number) {
    return new Promise<any>((resolve) =>
        // axios.get(`${config.baseUrl}accounts/${user_id}/fetch-account/`,)
        axios.get(`${config.baseUrl}tribunals/fetch-by-tourna-id/${user_id}`,)
            .then((res) => {
                resolve(res);
            })
    )
}

export function fetchTribunalMembers(tribunal_id: number) {
    return new Promise<any>((resolve) =>
        // axios.get(`${config.baseUrl}accounts/${user_id}/update-account/`,)
        axios.get(`${config.baseUrl}tribunal/${tribunal_id}/memberships/`,)
            .then((res) => {
                console.log('fetched tribunal members ', res);
                resolve(res);
            })
    )
}

export function removeTribunalMember(tribunal_id: number, user_id: string) {
    return new Promise<any>((resolve) =>
        // axios.get(`${config.baseUrl}accounts/${user_id}/update-account/`,)
        axios.post(`${config.baseUrl}tribunals/${tribunal_id}/remove-member/`,  { "phone_no": user_id })
            .then((res) => {
                console.log('removed tribunal member', res);
                resolve(res);
            })
    )
}

export function getTribunalsByCreator(userID: number) {
    return new Promise<any>((resolve) =>
        // axios.get(`${config.baseUrl}accounts/${user_id}/update-account/`,)
        axios.get(`${config.baseUrl}tribunals/filter-by-creator/${userID}/`)
            .then((res) => {
                console.log('get user tribunals', res);
                resolve(res);
            })
    )
}

export function createTribunal(tribunalObj: any) {
    return new Promise<any>((resolve) =>
        // axios.get(`${config.baseUrl}accounts/${user_id}/update-account/`,)
        axios.post  (`${config.baseUrl}tribunals/create-tribunal/`, tribunalObj)
            .then((res) => {
                console.log('create tribunals', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('failed to create tribunal ', e.message);
            })
    )
}