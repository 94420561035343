import React from 'react';
import './InputNumber.css';

interface InputNumberProps {
    min: number;
    value: number;
    onChange: (value: number) => void;
}

const InputNumber: React.FC<InputNumberProps> = ({ min, value, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue) && newValue >= min) {
            onChange(newValue);
        }
    };

    return (
        <input
            type="number"
            onChange={handleChange}
            min={min}
            className="input-number"
        />
    );
};

export default InputNumber;
