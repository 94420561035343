import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { cancelSeason, fetchSeasonParticipations, startSeason, startSeasonRound, fetchSeasonPayout } from './seasonAPI';


export interface Season {
    id?: number;
    count: number
    joinedParticipantsCount: number,
    registrationDate: string | undefined;    
    winner_id?: number
    tourna_id: number
    startDate: string | undefined
    status: "pending" | "ongoing" | "complete" | "canceled"
    payout: number
    round: number
    code: string
    currentLevel: 'groups' | string;
    createdAt?: string | undefined;
    updatedAt?: string | undefined;
    deletedAt?: string | undefined;
}

export interface SeasonParticipation {
    id?: string,
    player_id: number,
    username: string,
    season_id: number,
    status: 'join_request' | 'active' | 'eliminated' | 'revoked'
    join_otp: string | undefined;
    createdAt: string | undefined;
    updatedAt: string | undefined;
}

export interface SeasonParticipationData extends SeasonParticipation {

}

export interface SeasonState {
    selectedView: 'schedule' | 'standing' | 'my-matches' | 'chat' | 'posts';
    value: Season | undefined
    selectedSeason: Season | undefined
    allSeasons: Season[] | undefined
    confirmedJoin: boolean
    status: 'idle' | 'loading' | 'failed';
    seasonParticipation: SeasonParticipation | undefined
    seasonParticipations: SeasonParticipation[];
    seasonParticipantsData: any
    errorMsg: string | undefined
    successMsg: string | undefined
}

const initialState: SeasonState = {
    selectedView: 'standing',
    confirmedJoin: false,
    value: undefined,
    selectedSeason: undefined,
    allSeasons: undefined,
    status: 'idle',
    seasonParticipation: undefined,
    seasonParticipations: [],
    seasonParticipantsData: {},
    errorMsg: undefined,
    successMsg: undefined,
};

export const fetchSeasonParticipationsAsync = createAsyncThunk(
    'fetchSeasonParticipations/',
    async (seasonID: number) => {
        const response = await fetchSeasonParticipations(seasonID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya fetchSeasonParticipationsAsync', response.data);
        return response.data;
    }
);

export const startSeasonAsync = createAsyncThunk(
    'startSeason/',
    async (startSeasonParams: {seasonID: number, tournaID: number }) => {
        const response = await startSeason(startSeasonParams.seasonID, startSeasonParams.tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya startSeason', response.data);
        return response.data;
    }
);

export const cancelSeasonAsync = createAsyncThunk(
    'cancelSeason/',
    async (seasonID: number) => {
        const response = await cancelSeason(seasonID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya cancelSeasonAsync', response.data);
        return response.data;
    }
);

export const startSeasonRoundAsync = createAsyncThunk(
    'startSeasonRound/',
    async (seasonID: number) => {
        const response = await startSeasonRound(seasonID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya startSeasonRoundAsync', response.data);
        return response.data;
    }
);

export const fetchSeasonPayoutAsync = createAsyncThunk(
    'seasonPayout/',
    async (seasonID: number) => {
        const response = await fetchSeasonPayout(seasonID);
        // The value we return becomes the `fulfilled` action payload
        console.log('response ya fetchSeasonPayoutAsync', response.data);
        return response.data;
    }
);

export const seasonSlice = createSlice({
    name: 'season',
    initialState,
    reducers: {
        setSelectedView: (state, action: PayloadAction<'schedule' | 'standing' | 'my-matches' | 'chat' | 'posts'>) => {
            state.selectedView = action.payload
        },
        setSeason: (state, action: PayloadAction<Season>) => {
            state.value = action.payload
        },
        setSeasonParticipation: (state, action: PayloadAction<SeasonParticipation | undefined>) => {
            state.seasonParticipation = action.payload
        },
        setSeasonParticipantsData: (state, action: PayloadAction<any>) => {
            state.seasonParticipantsData = action.payload
        },
        setConfirmedJoin: (state, action: PayloadAction<boolean>) => {
            state.confirmedJoin = action.payload
        },
        setSeasonErrorMsg: (state, action: PayloadAction<string | undefined>) => {
            state.errorMsg = action.payload
        },
        setSeasonSuccessMsg: (state, action: PayloadAction<string | undefined>) => {
            state.successMsg = action.payload
        },
        clearSeasonMessages: (state, action: PayloadAction) => {
            state.successMsg = undefined
            state.errorMsg = undefined
        },
        setSeasonParticipations: (state, action: PayloadAction<SeasonParticipation[]>) => {
            state.seasonParticipations = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeasonParticipationsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSeasonParticipationsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload.code === 'success')
                    state.seasonParticipations = action.payload.data
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            })
            .addCase(startSeasonAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(startSeasonAsync.fulfilled, (state, action) => {
                console.log('res ya start season ', action.payload)
                state.status = 'idle';
                if (action.payload.code === 'success')
                    if (state.selectedSeason)
                        state.selectedSeason.status = "ongoing"
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            })
            .addCase(startSeasonRoundAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(startSeasonRoundAsync.fulfilled, (state, action) => {
                console.log('res ya start season round ', action.payload)
                state.status = 'idle';
                if (action.payload.code === 'success') {
                    if (state.selectedSeason)
                        state.selectedSeason.status = "ongoing"
                    state.successMsg = action.payload.detail
                    state.errorMsg = undefined
                }
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            })
            .addCase(fetchSeasonPayoutAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSeasonPayoutAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload.code === "success") {
                    state.errorMsg = undefined;
                    state.successMsg = action.payload.detail;
                    state.successMsg = "Successfully fetched season payout";
                    // state.seasonParticipation = action.payload.data
                }
                if (action.payload.code === 'error') {
                    console.log('requestJoinSeasonOTPAsync error', action.payload.detail)
                    // state.errorMsg = action.payload.detail;
                    state.errorMsg = 'Failed to fetch season payout';
                    state.successMsg = undefined;
                }
            })
    },
});

export const { setSelectedView, setSeason, setConfirmedJoin, setSeasonParticipation, setSeasonParticipations,
    setSeasonErrorMsg, setSeasonSuccessMsg, setSeasonParticipantsData, clearSeasonMessages } = seasonSlice.actions;

export const selectSelectedView = (state: RootState) => state.season.selectedView;
export const selectSeason = (state: RootState) => state.season;

export default seasonSlice.reducer;
