import jwt_decode, { JwtPayload } from "jwt-decode";
import axios from "axios";

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './app/store';
import { refreshAccessToken } from './appAPI';
import { loginAsync } from "./features/login/loginSlice";
// import { fetchProfileByUserIDAsync, Profile, updateProfileAsync } from "./features/profile/profileSlice";
// import { postScoresAsync } from "./features/view-conflict/viewConflictSlice";

export interface AppState {
    status: 'idle' | 'loading' | 'failed';
    appBarColor: string,
    orange1: string,
    orange2: string,
    blue1: string,
    blue2: string,
    accessToken: string | undefined,
    userID: number | undefined,
    userAccountID: string | undefined,
    userName: string | undefined
    errorMsg: string | undefined
    successMsg: string | undefined
    settingOTP: boolean
    signingUp: boolean
    timeouts: any[]
    // profile: Profile | undefined
}

const initialState: AppState = {
    appBarColor: 'red',
    orange1: '#fca103',
    orange2: '#fc6603',
    blue1: '#02165e',
    blue2: '#4a61b5',
    accessToken: undefined,
    status: 'idle',
    userID: undefined,
    userAccountID: undefined,
    userName: undefined,
    errorMsg: undefined,
    successMsg: undefined,
    signingUp: false,
    settingOTP: false,
    timeouts: [],
    // profile: undefined
};


export const refreshAccessTokenAsync = createAsyncThunk(
    'app/',
    async (token: string | null) => {
        const response: { access: string, refresh: string } = await refreshAccessToken(token);
        if (response.refresh) {
            // if (response.code) {
            // The value we return becomes the `fulfilled` action payload
            localStorage.setItem("refresh", response.refresh);
            setAccessToken(response.access);
            return response;
        } else {
            // TODO
            console.log('change app bar color at this point');
            return {
                access: '',
                refresh: ''
            };
        }
    }
);

let accessToken: string | undefined;
export function setAccessToken(token: string) {
    accessToken = token;
    if (token) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    else delete axios.defaults.headers.common["Authorization"];
}

export function getAccessToken(): string | undefined {
    return accessToken;
}

export function isTokenExpired(token: string) {
    if (token)
        try {
            const jwtPayload: JwtPayload = jwt_decode(token);
            if (isJWTPayload(jwtPayload)) {
                const currentTime = Date.now() / 1000;
                if (jwtPayload.exp)
                    return jwtPayload.exp < currentTime;
            }
        } catch (err) {
            return true;
        }
    return true;
}

function isJWTPayload(value: JwtPayload | String | null): value is JwtPayload {
    return (value as JwtPayload).exp !== undefined;
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAccess: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        setAppBarColor: (state, action: PayloadAction<string>) => {
            state.appBarColor = action.payload;
        },
        setUserId: (state, action: PayloadAction<number>) => {
            state.userID = action.payload;
        },
        setUserName: (state, action: PayloadAction<string>) => {
            state.userName = action.payload;
        },
        setSettingOTP: (state, action: PayloadAction<boolean>) => {
            state.settingOTP = action.payload;
        },
        setSigningUp: (state, action: PayloadAction<boolean>) => {
            state.signingUp = action.payload;
        },
        setErrorMsg: (state, action: PayloadAction<string | undefined>) => {
            state.errorMsg = action.payload;
        },
        addTimeout: (state, action: PayloadAction<any>) => {
            state.timeouts.push(action.payload);
        },
        clearTimouts: (state, action: PayloadAction) => {
            console.log('cklaring timeouts ', state.timeouts)
            for(let i = 0; i < state.timeouts.length; i++)
                clearTimeout(state.timeouts[i]);
            state.timeouts = [];
        },
        clearAppMessages: (state, action: PayloadAction) => {
            state.successMsg = undefined
            state.errorMsg = undefined
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(refreshAccessTokenAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(refreshAccessTokenAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.accessToken = action.payload.access;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                localStorage.setItem("refresh", action.payload.refresh);
                setAccessToken(action.payload.access);
                state.accessToken = action.payload.access;
                const jwtPayload: JwtPayload & { user_id: number } = jwt_decode(action.payload.refresh);
                console.log("jwtPayload ", jwtPayload)
                if (isJWTPayload(jwtPayload))
                    state.userID = jwtPayload?.user_id
            })
            .addCase(loginAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginAsync.rejected, (state, action) => {
                console.log('login resulttts ', action)
            })
            // .addCase(fetchProfileByUserIDAsync.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(fetchProfileByUserIDAsync.fulfilled, (state, action) => {
            //     state.status = 'idle';
            //     if(action.payload.code === "success"){
            //         state.profile = action.payload.data;
            //     } else {
            //         state.errorMsg = "failed to fetch profile details"
            //     }
            // })
            // .addCase(updateProfileAsync.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(updateProfileAsync.fulfilled, (state, action) => {
            //     state.status = 'idle';
            //     state.profile = action.payload;
            // })
            // .addCase(postScoresAsync.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(postScoresAsync.fulfilled, (state, action) => {
            //     state.status = 'idle';
            //     if (action.payload.code === "error") {
            //         state.errorMsg = undefined;
            //         state.successMsg = undefined;
            //     }
            // });
    }
});

export const { setAccess, setAppBarColor, addTimeout, clearTimouts,
    setUserId, setErrorMsg, setSettingOTP, setSigningUp, clearAppMessages
} = appSlice.actions;

export const selectAccessToken = (state: RootState) => state.app.accessToken;

export default appSlice.reducer;
