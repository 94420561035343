import React from 'react';
import './PrizeDistributionPreview.css';

interface PrizeDistributionPreviewProps {
    distribution: number[];
}

const PrizeDistributionPreview: React.FC<PrizeDistributionPreviewProps> = ({ distribution }) => {
    return (
        <div className="prize-distribution-preview">
            <h5>prize distribution review</h5>
            {!distribution.length && <p>distribution updates based on your selections will be shown here.</p>}
            {distribution.length > 0 && <ul className="distribution-list">
                {distribution.map((amount, index) => {
                    const numericAmount = typeof amount === 'number' ? amount : Number(amount) || 0;
                    return (
                        <li key={index}>
                            <span>position {index + 1}:</span> <span>{numericAmount.toFixed(2)}/=</span>
                        </li>
                    );
                })}
            </ul>}
        </div>
    );
};

export default PrizeDistributionPreview;
