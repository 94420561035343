import axios from 'axios';
import { Tourna } from '../tourna/tournaSlice';
import config from '../../config.json';

export function addTourna(tourna: Tourna) {
    return axios.post(`${config.baseUrl}tournas`, tourna)
            .then((res) => {
                console.log('addTourna res ', res);
                return res;
            })
    ;
}