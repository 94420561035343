import React from 'react';
import './RadioGroup.css';

interface RadioOption {
    label: string;
    value: string;
}

interface RadioGroupProps {
    options: RadioOption[];
    value: string;
    onChange: (value: string) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ options, value, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("event ", event);
        onChange(event.target.value);
    };

    return (
        <div className="radio-group">
            {options.map((option) => (
                <label key={option.value} className="radio">
                    <input
                        type="radio"
                        value={option.value}
                        checked={value === option.value}
                        onChange={handleChange}
                    />
                    {option.label}
                </label>
            ))}
        </div>
    );
};

export default RadioGroup;
