import { User } from "./userSlice";

// A mock function to mimic making an async request for data
export function fetchUser() {
    return new Promise<{ code: string, data: User }>((resolve) =>
        setTimeout(() => resolve({
            code: "success", data:
            {
                id: "823473",
                username: "Aloo",
                userphrase: "First body",
                phone: "0778348733",
                rating: 1200,
            }
        }), 1100)
    );
}
