import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Conflict } from '../conflict/conflictSlice';
import { fetchConflicts, searchConflicts } from './conflictsAPI';

export interface ConflictsState {
    conflicts: Conflict[];
    resolvedTournaConflicts: Conflict[]
    status: 'idle' | 'loading' | 'failed';
    errorMsg: string | undefined;
    isSearching: boolean
}

const initialState: ConflictsState = {
    conflicts: [],
    resolvedTournaConflicts: [],
    status: 'idle',
    errorMsg: undefined,
    isSearching: false,
};

export const fetchConflictsAsync = createAsyncThunk(
    'conflicts/',
    async (tournaID: number) => {
        const response = await fetchConflicts(tournaID);
        return response.data
    }
);

export const searchConflictsAsync = createAsyncThunk(
    'searchConflicts/',
    async (searchString: string) => {
        const response = await searchConflicts(searchString);
        // The value we return becomes the `fulfilled` action payload
        console.log('searchConflictsAsync response ', response);
        return response.data;
    }
);

export const conflictsSlice = createSlice({
    name: 'conflicts',
    initialState,
    reducers: {
        setConflicts: (state, action: PayloadAction<Conflict[] | any>) => {
            state.conflicts = action.payload
        },
        addSingleConflict: (state, action: PayloadAction<Conflict>) => {
            state.conflicts.push(action.payload);
        },
        addManyConflicts: (state, action: PayloadAction<Conflict[]>) => {
            state.conflicts.concat(action.payload);
        },
        setResolvedTournaConflicts: (state, action: PayloadAction<Conflict[]>) => {
            console.log('setting round conflicts ...', action.payload)
            state.resolvedTournaConflicts = action.payload
        },
        setConflictsErrorMsg: (state, action: PayloadAction<string| undefined>) => {
            console.log('setting round conflicts ...', action.payload)
            state.errorMsg = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchConflictsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchConflictsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                console.log("conflicts afterwards rrrrr")
                console.log(action.payload.data)
                if (action.payload.code === 'success') {
                    console.log("conflicts afterwards rrrrr byayaya")
                    state.conflicts = action.payload.data
                    console.log(state.conflicts)
                }
                if (action.payload.code === 'error')
                    state.errorMsg = action.payload.detail
            })
            .addCase(searchConflictsAsync.pending, (state) => {
                state.status = 'loading';
                state.isSearching = true;
            })
            .addCase(searchConflictsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.conflicts = action.payload;
                state.isSearching = false;
            });
    },
});

export const { setConflicts, setResolvedTournaConflicts, setConflictsErrorMsg } = conflictsSlice.actions;

export const selectConflicts = (state: RootState) => state.conflictsList;

export default conflictsSlice.reducer;
