import axios from "axios";
import config from '../../config.json';

export function addTribunalMembership(addMemberObj: { phone: string, tribunal_id: number }) {
    return new Promise<any>((resolve) => {
        // axios.get(`${config.baseUrl}accounts/${user_id}/update-account/`,)
        axios.post(`${config.baseUrl}tribunal/${addMemberObj.tribunal_id}/add-member/`, addMemberObj)
            .then((res) => {
                console.log('add tribunal membership ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('failed to add tribunal membership ', e.message);
            });
    })
}

export function removeTribunalMembership(remMemberObj: { phone: string, tribunal_id: number }) {
    return new Promise<any>((resolve) => {
        // axios.get(`${config.baseUrl}accounts/${user_id}/update-account/`,)
        axios.post(`${config.baseUrl}tribunal/${remMemberObj.tribunal_id}/remove-member/`, remMemberObj)
            .then((res) => {
                console.log('add tribunal membership ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('failed to remove tribunal membership ', e.message);
            });
    })
}
