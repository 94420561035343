import React, { useEffect, useState } from "react";
import styles from "./BottomNav.module.css"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import {
//     setSelectedView,
// } from '../lobby/lobbySlice';
import { useNavigate } from "react-router";
import { setAppBarTitle } from "../app-bar/appBarSlice";
// import { setShowChatInput } from "../view-match-modal/viewMatchSlice";

const BottomNav: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const buttons = ['lounges', 'tournas', 'tribunals', 'profile'];
    // const showChatInput = useAppSelector<boolean>(state => state.viewMatchDetails.showChatInput);

    // useEffect(() => {
    //     if(showChatInput)
    //         dispatch(setShowChatInput(true))
    //     else dispatch(setShowChatInput(false))
    // }, [showChatInput, dispatch]);

    const userID = useAppSelector(state => state.app.userID);
    const [, setUserIn20] = useState<boolean>(false);
    
    useEffect(() => {
        if(userID)
            if (userID < 20)
                setUserIn20(true)
    }, [setUserIn20, userID]);

    return (
        <div className={styles.BottomNav}>
            {buttons.map((b: string) => {
                return <button key={b} onClick={() => {
                    // if(b==='profile') b = 'profile';
                    dispatch(setAppBarTitle(b));
                    navigate(`/${b}`)
                    // dispatch(setSelectedView(b))
                }}>{b}</button>
            })}
        </div>
    );
};

export default BottomNav;
