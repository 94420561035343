import axios from "axios";
import config from '../../config.json';

export function postPrizeDistribution(prizeDstrPayload: {tournaID: number, prizeDistribution: any[]}) {

    return new Promise<any>((resolve) =>
        axios.post(`${config.baseUrl}tournas/${prizeDstrPayload.tournaID}/post-prize-distribution/`, {prizeDistribution: prizeDstrPayload.prizeDistribution})
            .then((res) => {
                console.log('posted prize distribution ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('postPrizeDistribution ', e.message);
            })
    )
}