import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, } from '../../app/store';
import { assignTribunal } from './selectTribunalAPI';

export interface Tribunal {
    id?: number;
    is_active: boolean
    creator?: string
    creator_id: number
    name: string
}

export interface TribunalState {
    tribunal: Tribunal | undefined;
    myTribunals: Tribunal[],
    status: 'idle' | 'loading' | 'failed';
    errorMsg: string | undefined
    successMsg: string | undefined
}

const initialState: TribunalState = {
    tribunal: undefined,
    myTribunals: [],
    status: 'idle',
    errorMsg: undefined,
    successMsg: undefined,
};

export const assignTribunalAsync = createAsyncThunk(
    'assignTribunal/',
    async (tribunalObj: { tribunal_id: number, tourna_id: number }) => {
        const response = await assignTribunal(tribunalObj);
        // The value we return becomes the `fulfilled` action payload
        console.log('create tribunal ', response);
        return response.data;
    }
);

export const selectTribunalSlice = createSlice({
    name: 'selectTribunal',
    initialState,
    reducers: {
        setTribunal: (state, action: PayloadAction<Tribunal|undefined>) => {
            state.tribunal = action.payload
        },
        setSelectTribunalModalError: (state, action: PayloadAction<string|undefined>) => {
            state.errorMsg = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(assignTribunalAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(assignTribunalAsync.fulfilled, (state, action: any) => {
                console.log('res ya tribunal members ', action.payload)
                state.status = 'idle';
                // console.log("new season afterwards rrrrr")
                if (action.payload.id) {
                    state.successMsg = "successfully assigned tribunal to tourna.";
                    state.tribunal = action.payload.data
                } else
                    state.errorMsg = action.payload.detail;
            })
            .addCase(assignTribunalAsync.rejected, (state, action) => {
                state.status = 'idle';
                console.log("rejected, (NEW!) check it out ....")
            });
    }
});

export const { setTribunal, setSelectTribunalModalError } = selectTribunalSlice.actions;

export const selectProfile = (state: RootState) => state.selectTribunalState

export default selectTribunalSlice.reducer;
