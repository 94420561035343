import axios from "axios";
import config from '../../config.json';


export function fetchSeasonParticipations(seasonID: number) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}season-participations/${seasonID}/get-season-participations/`)
            .then((res) => {
                console.log('fetched participations ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('fetchTournaParticipations ', e.message);
            });
    })
}

export function startSeason(seasonID: number, tournaID: number) {
    return new Promise<any>((resolve) => {
        axios.post(`${config.baseUrl}seasons/${seasonID}/start-season/`, {tourna_id: tournaID})
            .then((res) => {
                console.log('started season ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('started season attempt ', e.message);
            });
    })
}

export function cancelSeason(tournaID: number) {
    return new Promise<any>((resolve) => {
        axios.get(`${config.baseUrl}seasons/${tournaID}/cancel-season/`)
            .then((res) => {
                console.log('cancelled season ', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('cancelling season attempt failed ', e.message);
            });
    })
}

export function startSeasonRound(tournaID: number) {
    return new Promise<any>((resolve) => {
        axios.post(`${config.baseUrl}seasons/${tournaID}/start-round/`)
            .then((res) => {
                console.log('started tourna round', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('start tourna round attempt ', e.message);
            });
    })
}

export function fetchSeasonPayout(seasonID: number) {
    return new Promise<any>((resolve) => {
        axios.post(`${config.baseUrl}seasons/${seasonID}/start-round/`)
            .then((res) => {
                console.log('started tourna round', res);
                resolve(res);
            }).catch((e: Error) => {
                console.log('start tourna round attempt ', e.message);
            });
    })
}