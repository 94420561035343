import { Game } from "../game/gameSlice";

// A mock function to mimic making an async request for data
export function fetchGames() {
    return new Promise<{ code: string, data: Game[] }>((resolve) =>
        setTimeout(() => resolve({
            code: "success", data: [
                {
                    id: "1",
                    name: "FIFA",
                    code: "FIFA",
                    frequentRules: [
                        '10 mins', 'tactical defending'
                    ],
                    createdAt: new Date().toISOString(),
                },
                {
                    id: "2",
                    name: "Mortal Kombat",
                    code: "MK",
                    frequentRules: [
                        '10 mins', '15'
                    ],
                    createdAt: new Date().toISOString(),
                },
                {
                    id: "3",
                    name: "Chess",
                    code: "CHESS",
                    frequentRules: [
                        'blitz 20 mins', 'blitz 10 mins', 'untimed'
                    ],
                    createdAt: new Date().toISOString(),
                },
                {
                    id: "4",
                    name: "Maths",
                    code: "MAO",
                    frequentRules: [
                        'no referring', 'referring allowed'
                    ],
                    createdAt: new Date().toISOString(),
                },
                {
                    id: "5",
                    name: "Madden",
                    code: "MDDN",
                    frequentRules: [
                        'no referring', 'referring allowed'
                    ],
                    createdAt: new Date().toISOString(),
                },
                {
                    id: "6",
                    name: "F1",
                    code: "F1",
                    frequentRules: [
                        'no referring', 'referring allowed'
                    ],
                    createdAt: new Date().toISOString(),
                },
                {
                    id: "7",
                    name: "NHL",
                    code: "NHL",
                    frequentRules: [
                        'no referring', 'referring allowed'
                    ],
                    createdAt: new Date().toISOString(),
                },
                {
                    id: "8",
                    name: "UFC",
                    code: "UFC",
                    frequentRules: [
                        'no referring', 'referring allowed'
                    ],
                    createdAt: new Date().toISOString(),
                },
            ]
        }), 1100)
    );
}
