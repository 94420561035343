import React from "react";
import { useAppSelector } from "../../app/hooks";
import { MatchReport, selectViewConflictDetails } from "../view-conflict/viewConflictSlice";
import styles from "./MatchReportSummary.module.css";

interface MatchReportSummaryProps {
    matchReport: MatchReport
}

const MatchReportSummary: React.FC<MatchReportSummaryProps> = (props) => {

    const { matchReport } = props

    const match = useAppSelector(selectViewConflictDetails).match

    const userID = useAppSelector(state => state.app.userID)

    return (
        <>
            {match && <div className={styles.MatchReportSummary}>
                <h6>
                    {(match.player_one_id?.toString() === userID ? "My" : match.player_one_username + "'s ") + " score : "
                        + matchReport.player_one_score}
                </h6>
                <h6>
                    {(match.player_two_id?.toString() === userID ? "My" : match.player_two_username + "'s ") + " score : "
                        + matchReport.player_two_score}
                </h6>

            </div>}
            {!match && <div>
                No match selected
            </div>}
        </>

    );
};

export default MatchReportSummary;
