export const randomString = (length: number) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export interface ErrorInterface {
    code: 'error',
    detail: any,
    data?: any,
}

export interface SuccessInterface {
    code: 'success',
    detail: string,
    data?: any    
}