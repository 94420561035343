import { FormEvent, FormEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './Rules.module.css';
import msgStyles from '../../message_styles.module.css';
import { setRulesValue, setRulesFilePath, } from './rulesSlice';
import RefreshIcon from '../../assets/icons/512px-Refresh_icon.svg.png';
import { clearAppMessages, clearTimouts, isTokenExpired, setErrorMsg } from '../../appSlice';
// import { Match } from '../match/matchSlice';
import { useNavigate } from 'react-router-dom';
import { createTournaTribunalAsync, getTournaOrganiserAsync, updateTournaDescriptionAsync } from '../tourna/tournaSlice';
import CloseIcon from '../../assets/icons/close_icon.png';
import SelectTribunalModal from '../select-tribunal-modal/SelectTribunalModal';
import DefinePayoutModal from '../define-payout-modal/DefinePayoutModal';

interface RulesInterface {
}

const Rules: React.FC<RulesInterface> = (props) => {
    const dispatch = useAppDispatch();
    const tourna = useAppSelector(state => state.tourna);
    const app = useAppSelector(state => state.app);
    const rules = useAppSelector(state => state.rulesData.rules);
    const [matchesPerRound, setMatchesPerRound] = useState<number>();
    const [numberOfRounds, setNumberOfRounds] = useState<number>(1);
    const [alreadyJoined, setAlreadyJoined] = useState<boolean>(false);
    const [seasonFull, setSeasonFull] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);
    const tournaSuccessMsg = tourna.successMsg, tournaErrorMsg = tourna.errorMsg;
    const navigate = useNavigate();
    const userID = useAppSelector(state => state.app.userID);
    const tournaOrganiser = useAppSelector(state => state.tourna.value?.organiser_id)
    const myTribunals = useAppSelector(state => state.tribunalState.myTribunals)
    const [showComposeTribunalModal, setShowComposeTribunalModal] = useState(false);
    const [blurred, setBlurred] = useState('');
    const createTribunalErrorMsg = useAppSelector(state => state.tribunalState.errorMsg);
    const createTribunalSuccessMsg = useAppSelector(state => state.tribunalState.successMsg);
    const [tournaDescription, setTournaDescription] = useState<string | undefined>(tourna.value?.description);
    const [showDefinePayoutModal, setShowDefinePayoutModal] = useState(false);

    useEffect(() => {
        console.log("tournaOrganiser here ", tournaOrganiser)
        if (!tournaOrganiser && tourna.value?.id) {
            console.log("gets here 4837")
            dispatch(getTournaOrganiserAsync(tourna.value.id))
        }

    }, [tournaOrganiser, dispatch, tourna.value?.id])

    const fetchTournaOrganiser = () => {
        if (tourna.value?.id) {
            dispatch(getTournaOrganiserAsync(tourna.value.id))
            navigate(`/profile/${tourna.value?.organiser_id}/`)
        }
        else dispatch(setErrorMsg("no tourna detected"))
    }

    const composeTribunalModalOnClick = () => {
        // dispatch(clearAppMessages());
        // console.log("check here tourna.value?.tribunal_id", tourna.value?.tribunal_id)
        // if (tourna.value?.id && !tourna.value?.tribunal_id && (userID == tourna.value?.organiser_id))
        //     dispatch(createTournaTribunalAsync(tourna.value?.id))
        // navigate(`/tribunal/${tourna.value?.tribunal_id}/`)
        setShowComposeTribunalModal(true)
        setShowDefinePayoutModal(false)
    }

    const definePayoutOnClick = () => {
        setShowDefinePayoutModal(true)
        setShowComposeTribunalModal(false)
    }

    useEffect(() => {
        if (showComposeTribunalModal || showDefinePayoutModal)
            setBlurred("blurred");
        else setBlurred("");
    }, [showComposeTribunalModal, showDefinePayoutModal]);

    useEffect(() => {
        console.log('tourna error message ', createTribunalErrorMsg)
        if (createTribunalSuccessMsg && !createTribunalErrorMsg) {
            dispatch(setErrorMsg(createTribunalSuccessMsg))
        }
        if (createTribunalErrorMsg && !createTribunalSuccessMsg) {
            dispatch(setErrorMsg(createTribunalErrorMsg))
        }
    }, [createTribunalSuccessMsg, createTribunalErrorMsg, tourna.value?.tribunal_id]);

    const submitDescriptionChange = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (tourna.value?.id && tournaDescription) {
            dispatch(updateTournaDescriptionAsync({ tournaID: tourna.value?.id, description: tournaDescription }))
        }
    }

    const handleDescriptionChange = (e: any) => {
        setTournaDescription(e.target.value);
    };

    useEffect(() => {
        if (tournaSuccessMsg && !tournaErrorMsg) {
            setInfoMessage(undefined);
            setErrorMessage(undefined);
            setSuccessMessage(tournaSuccessMsg);
        }
        if (tournaErrorMsg && !tournaSuccessMsg) {
            setInfoMessage(undefined);
            setErrorMessage(tournaErrorMsg);
            setSuccessMessage(undefined);
        }
        setTimeout(() => {
            setInfoMessage(undefined);
            setErrorMessage(undefined);
            setSuccessMessage(undefined);
            dispatch(clearTimouts())
        }, 3000)
    }, [tournaSuccessMsg, tournaErrorMsg, tourna.status, dispatch]);

    return (
        <div>
            <div className={styles[`Rules_${blurred}`]}>
                {errorMessage && <div className={msgStyles.error_msg}>
                    {errorMessage}
                </div>}
                {successMessage && <div className={msgStyles.success_msg}>
                    {successMessage}
                </div>}
                {infoMessage && <div className={msgStyles.info_msg}>
                    {infoMessage}
                </div>}
                <div>
                    {((tourna.value?.organiser_id !== userID) && !tournaOrganiser) && <button onClick={fetchTournaOrganiser}>view tourna organiser</button>}
                </div>

                <hr />

                {userID && (userID == tourna.value?.organiser_id) && rules && <div>
                    <button>edit rules</button>
                </div>}

                {userID && userID == tourna.value?.organiser_id && <div>
                    <p>tribunal assigned?: {tourna.value.tribunal_id && "yes"} {!tourna.value.tribunal_id && "not yet"}</p>
                    {!tourna.value.tribunal_id && <button onClick={composeTribunalModalOnClick}>compose tribunal</button>}
                    {tourna.value.tribunal_id && <button onClick={composeTribunalModalOnClick}>change/edit tribunal</button>}
                    <hr />
                </div>}

                {userID && userID == tourna.value?.organiser_id && <div>
                    <p>payout set?: {tourna.value.payout && "yes"} {!tourna.value.payout && "not yet"}</p>
                    {!tourna.value.tribunal_id && <button onClick={definePayoutOnClick}>define payout</button>}
                    {tourna.value.tribunal_id && <button onClick={definePayoutOnClick}>change/edit payout</button>}
                    <hr />
                </div>}


                <div>
                    {tourna.value && <div>
                        <h6>Update Model Description</h6>
                        <form onSubmit={submitDescriptionChange}>
                            <textarea
                                id="description"
                                value={tournaDescription}
                                onChange={handleDescriptionChange}
                                style={{
                                    resize: 'both',
                                    width: '300px',
                                    height: '150px'
                                }}
                            />
                            <button type="submit">Update Description</button>
                        </form>
                    </div>}
                    {tourna.value && <table>
                        <thead>
                            <tr>
                                <th>Rules</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>name</td>
                                <td>{tourna.value.name}</td>
                            </tr>
                            <tr>
                                <td>console</td>
                                <td>{tourna.value.console}</td>
                            </tr>
                            <tr>
                                <td>game</td>
                                <td>{tourna.value.game}</td>
                            </tr>
                            <tr>
                                <td>half length</td>
                                <td>{tourna.value.halfLength}</td>
                            </tr>
                            <tr>
                                <td>legacy defending</td>
                                <td>{tourna.value.legacyDefending}</td>
                            </tr>
                            <tr>
                                <td>private</td>
                                <td>{tourna.value.private}</td>
                            </tr>
                            <tr>
                                <td>description</td>
                                <td>{tourna.value.description}</td>
                            </tr>
                            <tr>
                                <td>status</td>
                                <td>{tourna.value.status}</td>
                            </tr>
                            <tr>
                                <td>entry fee</td>
                                <td>{tourna.value.entryFee}</td>
                            </tr>
                            <tr>
                                <td>number of participants</td>
                                <td>{tourna.value.numberOfParticipants}</td>
                            </tr>
                            <tr>
                                <td>tourna type</td>
                                <td>{tourna.value.tournaType}</td>
                            </tr>
                            <tr>
                                <td>code</td>
                                <td>{tourna.value.code}</td>
                            </tr>
                        </tbody>
                    </table>}
                </div>

                {userID && (userID == tourna.value?.organiser_id) && !rules && <div>
                    <button style={{ marginTop: '1vh' }}>upload additional rules pdf</button>
                </div>}
            </div>

            <div className={styles.Rules_trib_opt_modal_div}>
                {showComposeTribunalModal && !showDefinePayoutModal && (
                    <SelectTribunalModal closeModal={() => setShowComposeTribunalModal(false)} />
                )}
            </div>
            <div className={styles.Rules_trib_opt_modal_div}>
                {showDefinePayoutModal && !showComposeTribunalModal && (
                    <DefinePayoutModal ptAmnt={tourna.value?.payout || 0} participants={tourna.value?.joinedParticipantsCount || 0} onClose={() => setShowDefinePayoutModal(false)} />
                )}
            </div>
        </div>
    );
}

export default Rules
