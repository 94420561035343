import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { login } from './loginAPI';

export interface LoginInput {
    username: string | undefined;
    password: string | undefined;
}

export interface LoginState {
    loginInputs: LoginInput
    status: 'idle' | 'loading' | 'failed';
}

const initialState: LoginState = {
    loginInputs: {
        username: undefined,
        password: undefined
    },
    status: 'idle',
};

export const loginAsync = createAsyncThunk(
    'login/',
    async (loginInputs: LoginInput) => {
        console.log('response ya login ', loginInputs)
        const response = await login(loginInputs);
        
        return response.data;
    }
);


export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginInputs: (state, action: PayloadAction<LoginInput>) => {
            state.loginInputs = action.payload;
        },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = 'loading';
            })
    },
});

export const { setLoginInputs } = loginSlice.actions;

export const selectLoginDetails = (state: RootState) => state.loginDetails;

export default loginSlice.reducer;
