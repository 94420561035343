import axios from "axios";
import config from '../../config.json';

export const SEARCH_TOURNAS_URL = "tournas";

export function fetchSeasons(tournaID: number) {

    return new Promise<any>((resolve) =>
        axios.get(`${config.baseUrl}tournas/${tournaID}/get-tourna-seasons/`)
            .then((res) => {
                console.log('fetched seasons ', res);
                resolve(res.data);
            }).catch((e: Error) => {
                console.log('fetchSeasons ', e.message);
            })
    )
}

export function searchSeasons(searchString: string) {
    return new Promise<any>((resolve) =>
        axios.get(`${config.baseUrl}seasons/${searchString}`,)
            .then((res) => {
                console.log('searched seasons ', res);
                resolve(res.data);
            })
    )
}