import axios from "axios";
import config from '../../config.json';

export const SEARCH_TOURNAS_URL = "tournas";

export function fetchTournas() {
    return new Promise<any>((resolve) =>
        axios.get(`${config.baseUrl}tournas`,)
            .then((res) => {
                console.log('filtered tournaaas ', res);
                resolve(res);
            })
    )
}

export function searchTournas(searchString: string) {
    return new Promise<any>((resolve) =>
        axios.get(`${config.baseUrl}tournas/${searchString}`,)
            .then((res) => {
                console.log('searched tournaaas ', res);
                resolve(res);
            })
    )
}