import React, { useEffect, useState } from 'react';
import Button from '../../tools/button/Button';
import Modal from '../../tools/modal/Modal';
import Slider from '../../tools/slider/Slider';
import Select from '../../tools/select/Select';
import PrizeDistributionPreview from '../prize-distribution-preview/PrizeDistributionPreview';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import StandingsModeSelection from '../standings-mode-selection/StandingsModeSelection';
import { postPrizeDistributionAsync, setPayoutsErrorMsg } from './definePayoutModalSlice';
import Radio from '../../tools/radio/Radio';
import RadioGroup from '../../tools/radio-group/RadioGroup';
import InputNumber from '../../tools/input-number/InputNumber';


interface DefinePayoutModalProps {
    ptAmnt: number;
    participants: number;
    onClose: () => void;
}

const DefinePayoutModal: React.FC<DefinePayoutModalProps> = ({ ptAmnt, participants, onClose }) => {
    const [potAmount, setPotAmount] = useState(ptAmnt)
    const [selectionMode, setSelectionMode] = useState<'percentage' | 'number'>('percentage');
    const [topNumber, setTopNumber] = useState(1);
    const [topPercentage, setTopPercentage] = useState(1); // Default to top 1%
    const [distributionMethod, setDistributionMethod] = useState<string>('arithmetic');
    const tourna = useAppSelector(state => state.tourna);
    const [prizeDistribution, setPrizeDistribution] = useState(
        new Array(tourna.value?.joinedParticipantsCount).fill(0, 1).fill(tourna.value?.payout ? tourna.value?.payout : 0, 0, 1)
    );
    const dispatch = useAppDispatch();
    const payoutErrorMsg = useAppSelector<any>(state => state.definePayout.errorMsg)


    const handlePercentageChange = (value: number) => {
        setTopPercentage(value);
        // Update prize distribution when percentage changes
        calculateDistributionByPerc(value, distributionMethod);
    };

    const handleNumberChange = (value: number | null) => {
        if (value !== null && value > 0) {
            if (tourna.value?.joinedParticipantsCount && 
                (value > tourna.value?.joinedParticipantsCount)){
                    value = tourna.value.joinedParticipantsCount;
                }
            setTopNumber(value);
            let distribution = calculateDistribution(value, distributionMethod);
            setPrizeDistribution(distribution)
        }
    };

    const handleSelectionModeChange = (e: any) => {
        setSelectionMode(e);
    };

    useEffect(() => {
        handleDistributionMethodChange({target: {value: distributionMethod}});
    }, [distributionMethod])

    const handleDistributionMethodChange = (e: any) => {
        setDistributionMethod(e.target.value);
        // Update prize distribution when distribution method changes
        if (selectionMode == 'number') {
            let distribution = calculateDistribution(topNumber, distributionMethod);
            setPrizeDistribution(distribution)
        } else calculateDistributionByPerc(topPercentage, e.target.value);
    };

    const calculateDistributionByPerc = (topPercentage: number, method: string) => {
        const topParticipants = Math.ceil((topPercentage / 100) * participants);

        let distribution = calculateDistribution(topParticipants, method);
        setPrizeDistribution(distribution)
    };

    const calculateDistribution = (topParticipants: number, method: string) => {
        // Placeholder logic to distribute the pot based on the method selected
        let distribution: number[] | undefined = [];

        console.log("topParticipants: ", topParticipants, " method: ", method)
        switch (method) {
            case 'arithmetic':
                distribution = Array(topParticipants).fill(0).map((_, i) => Math.round(potAmount / topParticipants) + (i * Math.round(potAmount / topParticipants))).reverse();
                break;
            case 'geometric':
                distribution = Array.from({ length: topParticipants }, (_, i) => (potAmount / Math.pow(2, i)));
                break;
            case 'harmonic':
                distribution = Array.from({ length: topParticipants }, (_, i) => (potAmount / (i + 1)));
                break;
            case 'fibonacci':
                distribution = generateFibonacciDistribution(potAmount, topParticipants);
                break;
            default:
                break;
        }

        // You may want to normalize distribution if necessary
        console.log("calculated distribution:", distribution);
        const total = distribution.reduce((sum, value) => sum + value, 0);
        if (total > 0) {
            distribution = distribution.map(value => (value / total) * potAmount);
        }
        return distribution;
    }

    const generateFibonacciDistribution = (total: number, count: number): number[] => {
        if (count == 1) return [total]
        let fibonacci: number[] = [1, 1];
        for (let i = 2; i < count; i++) {
            fibonacci[i] = fibonacci[i - 1] + fibonacci[i - 2];
        }
        const sum = fibonacci.reduce((a, b) => a + b, 0);
        return fibonacci.map(value => (value / sum) * total).reverse();
    };

    const handleSave = () => {
        if (tourna.value?.id){
            dispatch(postPrizeDistributionAsync({tournaID: tourna.value?.id, prizeDistribution: prizeDistribution}));
        }
    }

    useEffect(() => {
        if (payoutErrorMsg) {
            setTimeout(() => {
                dispatch(setPayoutsErrorMsg(undefined));
            }, 3000);
        }
    }, [payoutErrorMsg, dispatch]);

    return (
        <Modal
            visible={true}
            title="set tourna payout"
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={handleSave}>
                    save payout
                </Button>,
            ]}
        >
            {payoutErrorMsg && <span style={{color: "red"}}>{payoutErrorMsg}</span>}
            <RadioGroup
                options={[
                    { label: 'percentage', value: 'percentage' },
                    { label: 'absolute number', value: 'number' }
                ]}
                value={selectionMode}
                onChange={handleSelectionModeChange}
            />

            {selectionMode === 'percentage' ? (
                <>
                    <Slider
                        min={1}
                        max={100}
                        value={topPercentage}
                        onChange={handlePercentageChange}
                    // tooltip={{ formatter: (value: any) => `${value}%` }}
                    />
                    <p>top {topPercentage}% of participants will receive the prize.</p>
                </>
            ) : (
                <>
                    <InputNumber
                        min={-1}
                        value={topNumber}
                        onChange={handleNumberChange}
                    />
                    <p>top {topNumber} participants will receive the prize.</p>
                </>
            )}



            <h4>select distribution methid</h4>
            <select
                defaultValue="arithmetic"
                onChange={handleDistributionMethodChange}
                style={{ width: '100%' }}
            >
                <option value="arithmetic">arithmetic</option>
                <option value="geometric">geometric</option>
                <option value="harmonic">harmonic</option>
                <option value="fibonacci">fibonacci</option>
            </select>

            <PrizeDistributionPreview distribution={prizeDistribution} />
            <StandingsModeSelection />
        </Modal>
    );
};

export default DefinePayoutModal;
