import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { Season } from '../season/seasonSlice';
import { fetchSeasons, searchSeasons } from './seasonsAPI';

export interface SeasonListState {
    seasons: Season[];
    mySeasons: Season[];
    status: 'idle' | 'loading' | 'failed';
    errorMsg: string | undefined
    isSearching: boolean
}

const initialState: SeasonListState = {
    seasons: [],
    mySeasons: [],
    status: 'idle',
    errorMsg: undefined,
    isSearching: false,
};

export const fetchSeasonsAsync = createAsyncThunk(
    'seasons/',
    async (tournaID: number) => {
        const response = await fetchSeasons(tournaID);
        // The value we return becomes the `fulfilled` action payload
        console.log('fetchSeasonsAsync response ', response);
        return response.data;
    }
);

export const searchSeasonsAsync = createAsyncThunk(
    'searchSeasons/',
    async (searchString: string) => {
        const response = await searchSeasons(searchString);
        // The value we return becomes the `fulfilled` action payload
        console.log('searchSeasonsAsync response ', response);
        return response.data;
    }
);

export const seasonsListSlice = createSlice({
    name: 'seasonsList',
    initialState,
    reducers: {
        setSeasonsList: (state, action: PayloadAction<Season[]>) => {
            state.seasons = action.payload
        },
        addNewSeason: (state, action: PayloadAction<Season>) => {
            state.seasons.push(action.payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeasonsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSeasonsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.seasons = action.payload
            })
            .addCase(searchSeasonsAsync.pending, (state) => {
                state.status = 'loading';
                state.isSearching = true;
            })
            .addCase(searchSeasonsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.seasons = action.payload;
                state.isSearching = false;
            });

    },
});

export const { setSeasonsList, addNewSeason, } = seasonsListSlice.actions;

export const selectSeasonsList = (state: RootState) => state.seasonsList.seasons;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const addSeason = (season: Season): AppThunk => (
    dispatch,
    getState
) => {
    const currentList = selectSeasonsList(getState());
    if (currentList.length === 0) {
        dispatch(addSeason(season));
    }
};

export default seasonsListSlice.reducer;
