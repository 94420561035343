import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchUser } from './userAPI';

export interface User {
    id?: string;
    phone?: string;
    username: string;
    userphrase: string;
    rating: number;
}

export interface UserState {
    value: User;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
    value: {username: "test lounge", userphrase: "description", rating: 0},
    status: 'idle'
};

export const fetchUserAsync = createAsyncThunk(
    'user/',
    async () => {
        const response = await fetchUser();
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.value = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.value = action.payload
            });
    },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.value;

export default userSlice.reducer;
