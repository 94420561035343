import React from "react";
import { Link } from "react-router-dom";
import styles from "./SideBar.module.css"

const SideBar: React.FC = () => {

    return (
        <div className={styles.SideBar}>
            <Link to={{ pathname: `/` }} key={'lobby'} className="list-group-item list-group-item-action">
                <div style={{width: "100%"}}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{"lobby"}</h5>
                        <small className="text-muted">3</small>
                    </div>
                </div>
            </Link>
            <Link to={{ pathname: `/tournas` }} key={'tournas'} className="list-group-item list-group-item-action">
                <div style={{width: "100%"}}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{"tournas"}</h5>
                        <small className="text-muted">2</small>
                    </div>
                </div>
            </Link>
            <Link to={{ pathname: `/profile` }} key={'singles'} className="list-group-item list-group-item-action">
                <div style={{width: "100%"}}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{"singles"}</h5>
                        <small className="text-muted">2</small>
                    </div>
                </div>
            </Link>
            <Link to={{ pathname: `/profile` }} key={'profile'} className="list-group-item list-group-item-action">
                <div style={{width: "100%"}}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{"profile"}</h5>
                        <small className="text-muted">2</small>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default SideBar;
